
/* body {
  margin: 0;
  padding: 0;
  background-image: url("./Assets/back.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
} */
::placeholder {
  color: rgb(255, 255, 255);
  
}
.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #37AA99;
}




/* index.css
body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

body::before,
body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

body::before {
  background-image: url("./Assets/bg_fix.png");
  background-attachment: fixed;
}

body::after {
  background-image: url("./Assets/bg_rot.png");
  width: 100%;
  animation: rotateAnimation 100s linear infinite; /* Adjust animation properties as needed 
} */

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes flipAnimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes backgroundAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
} 






  
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff3d -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}
* {
  scroll-behavior: smooth;
}


.card-item-div {
  transition: all 0.2s;
}
/* .card-item-div:hover {
  transform: rotatey(25deg);
  transition: transform 0.5s ease-in-out;
  background-color: #d2ccf837;
  background-repeat: no-repeat; 
  background-size: cover;
  background-position: center;
  
  .card_desc{
    display: block;
    color: rgb(0, 0, 0);
    }
} */
/* .card_desc{
    display: none;
}
.flip-card-inner {
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
} */
/* .card_winner:hover .flip-card-inner {
  transform: rotateX(180deg);
} */

/* .flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
} */

/* .flip-card-back {
  transform: rotateX(180deg);
}
.card_winner:hover{
  .card_winner{
    animation: flip 3s ease-in;
  }
  .card_desc_track{
    display: none;
  }
} */


/* .flip-card_leader {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  perspective: 1000px;
} */

.flip-card-inner_leader {
  border-radius: 15px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card_leader:hover .flip-card-inner_leader {
  transform: rotateX(180deg);
}

.flip-card-front_leader, .flip-card-back_leader {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
 
}
/* 
.flip-card-front_leader {
  background-color: #bbb;
  color: black;
} */

.flip-card-back_leader {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  transform: rotateX(180deg);
}



.glass-card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
}

.glass-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 32px rgba(255, 255, 255, 0.05);
}

.card-item-div {
  animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.glass-card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
  transform-style: preserve-3d; /* Ensures child elements are rendered in a 3D space */
}

.glass-card:hover {
  transform: translateY(-5px) rotateX(10deg) rotateY(5deg); /* Add 3D rotation on hover */
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 32px rgba(255, 255, 255, 0.05);
}
input::placeholder {
  text-align: center;
}

input::placeholder {
  color: rgba(3, 0, 0, 0.418); 

input[placeholder="Name"]::placeholder,
input[placeholder="Email"]::placeholder {
  color: rgb(3, 0, 0); 
}

input[placeholder="Message"]::placeholder {
  color: rgb(3, 0, 0); 
}
}

.card-item-div {
  /* Your existing styles */
  transition: transform 1s ease-in-out; /* Increase the duration to 1 second */
  transition-delay: 0.2s; /* Add a 0.2s delay before the transition starts */
}

.card-item-div:hover {
  transform: scale(1.05);
}
